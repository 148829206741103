
.box{
    background-color: rgb(21, 21, 22);
  
}

.CTN{
    background-color: rgb(13, 13, 14);
}

.checkbox-lg {
    width: 20px;  /* Adjust width as needed */
    height: 20px; /* Adjust height as needed */
  }