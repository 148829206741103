.main{
    background-image: url('../Images/background.jpg');
}

.auth h1:hover{
    border-bottom: 15px solid rgb(243, 244, 246, 0.3);
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    
}
